.showOnMobile {
    display: none;
}

.customSliderNextBtn {
    right: 10px !important;
}

.customSliderPrevBtn {
    left: 10px !important;
}

.customSliderNextBtn,
.customSliderPrevBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    z-index: 2;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    cursor: pointer;
}

.premade-parties-wrapper {
    box-sizing: border-box;
    width: 100%;
    max-width: 1032px;
    margin: 0 auto;
}

.premade-parties-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    margin-bottom: 40px;
}

.streamLobbyItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 856px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
}

.streamLobbyItemVideo {
    width: 100%;
    height: 406px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    margin-right: 30px;
    position: relative;
}

.streamLobbyItemVideo iframe * {
    width: 100% !important;
    height: 100% !important;
}

.streamLobbyItemInfoWrapper {
    width: 100%;
    height: auto;
    min-height: 208px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 24px;
}

.streamLobbyItemInfoFirstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.streamLobbyItemInfoFirstRowSlotsWrapper {
    display: flex;
    flex: 1;
}

.streamLobbyItemVideoCoverLive {
    font-family: 'Abel', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100px;
    min-height: 21px;
    background: #EB5757;
    border-radius: 6px;
    padding: 0 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    margin-right: 8px;
    text-transform: uppercase;
}


.streamLobbyItemQueueSlot {
    display: flex;
    flex-direction: row;
    padding: 2px 8px;
    width: auto;
    min-height: 21px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    margin-right: 8px;
}

.queueStatus ,
.queueInfo {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    color: #FFFFFF;
    text-align: center;
    opacity: 0.8;
}

.queueInfo {
    margin-left: 2px;
}

.streamLobbyPremadeType {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 130%;
    color: #FFFFFF;
    text-align: center;
    opacity: 0.8;
}

.serverAndGameFormat {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 110%;
    color: #B2B2B2;
}

.streamLobbyItemDescription {
    height: auto;
    font-family: 'ABC Social Condensed', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    color: #FFFFFF;
    max-width: 856px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.streamLobbyItemDescription span{
    color: #8be778;
}

.streamLobbyItemSlotsWrapper {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;
    overflow: hidden;
}

.slotsWrapper {
    position: relative;
    width: 100%;
}

.streamLobbyItemInfoBottomRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.streamLobbyItemQueueButtonAdd {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #24E543;
    border-radius: 8px;
    padding: 18px 24px;
    cursor: pointer;
}

.streamLobbyItemButtonIcon {
    display: block;
    width: 20px;
    height: 20px;
}

.join-button-queue{
    font-family: 'ABC Social Bold', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    color: #262626;
    margin: 0 8px 0 10px;
}

.join-button-price{
    display: block;
    font-family: 'ABC Social Bold', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    color: #262626;
}

.autoChargeText {
    font-family: 'ABC Social', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    text-align: center;
    color: #B2B2B2;
    margin-top: 8px;
}

.swiper-button-next-slots,
.swiper-button-prev-slots {
    z-index: 2 !important;
    top: initial !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    min-height: 48px;
    background: #FFFFFF;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: #000000 !important;
    margin: 0 !important;
    position: absolute !important;
}

.swiper-button-next-slots {
    right: 0 !important;
}

.swiper-button-prev-slots {
    left: 0 !important;
}

@media (max-width: 850px) {
    .customSliderNextBtn,
    .customSliderPrevBtn {
        display: none;
    }

    .premade-parties-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        padding: 0 16px;
    }

    .showOnDesktop {
        display: none;
    }

    .showOnMobile {
        display: block;
        align-self: flex-start;
    }

    .streamLobbyItem {
        display: block;
        padding: 0;
        margin-bottom: 16px;
        height: 618px;
    }

    .streamLobbyItemVideo {
        width: 100%;
        height: 200px;
    }

    .streamLobbyItemInfoWrapper {
        max-width: 100%;
        padding: 8px 8px;
    }

    .streamLobbyItemInfoFirstRow {
        margin-bottom: 12px;
    }

    .serverAndGameFormat {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 130%;
        color: #B2B2B2;
    }

    .streamLobbyItemDescription {
        padding-left: 0;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: initial;
        height: 45px;
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
    }

    .streamLobbyItemSlotsWrapper {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        height: auto;
        margin: 8px 0 16px 0;
    }

    .streamLobbyItemQueueButtonAdd {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 14px 21px;
        margin: 0;
    }

    .join-button-wrapper {
        display: flex;
        align-items: center;
    }

    .autoChargeText {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 130%;
        text-align: center;
        margin-top: 4px;
    }
}
