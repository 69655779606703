.showOnMobile {
  display: none;
}

.customSliderNextBtn {
  right: 10px !important;
}

.customSliderPrevBtn {
  left: 10px !important;
}

.customSliderNextBtn, .customSliderPrevBtn {
  width: 36px;
  height: 36px;
  z-index: 2;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  box-shadow: 0 15px 40px #00000026;
}

.premade-parties-wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;
}

.premade-parties-list {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-bottom: 40px;
  display: flex;
}

.streamLobbyItem {
  width: 100%;
  height: auto;
  max-width: 856px;
  background: #ffffff1a;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
}

.streamLobbyItemVideo {
  width: 100%;
  height: 406px;
  border-radius: 10px 10px 0 0;
  margin-right: 30px;
  position: relative;
  overflow: hidden;
}

.streamLobbyItemVideo iframe * {
  width: 100% !important;
  height: 100% !important;
}

.streamLobbyItemInfoWrapper {
  width: 100%;
  height: auto;
  min-height: 208px;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  display: flex;
  position: relative;
}

.streamLobbyItemInfoFirstRow {
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.streamLobbyItemInfoFirstRowSlotsWrapper {
  flex: 1;
  display: flex;
}

.streamLobbyItemVideoCoverLive {
  max-width: 100px;
  min-height: 21px;
  color: #fff;
  text-transform: uppercase;
  background: #eb5757;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  padding: 0 10px;
  font-family: Abel, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  display: flex;
}

.streamLobbyItemQueueSlot {
  width: auto;
  min-height: 21px;
  background: #fff3;
  border-radius: 4px;
  flex-direction: row;
  margin-right: 8px;
  padding: 2px 8px;
  display: flex;
}

.queueStatus, .queueInfo {
  color: #fff;
  text-align: center;
  opacity: .8;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 130%;
}

.queueInfo {
  margin-left: 2px;
}

.streamLobbyPremadeType {
  color: #fff;
  text-align: center;
  opacity: .8;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 130%;
}

.serverAndGameFormat {
  color: #b2b2b2;
  font-family: ABC Social, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.streamLobbyItemDescription {
  height: auto;
  color: #fff;
  max-width: 856px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: ABC Social Condensed, sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 100%;
  display: -webkit-box;
  overflow: hidden;
}

.streamLobbyItemDescription span {
  color: #8be778;
}

.streamLobbyItemSlotsWrapper {
  flex: 1;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  overflow: hidden;
}

.slotsWrapper {
  width: 100%;
  position: relative;
}

.streamLobbyItemInfoBottomRow {
  width: 100%;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.streamLobbyItemQueueButtonAdd {
  height: 60px;
  width: 100%;
  cursor: pointer;
  background: #24e543;
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  display: flex;
}

.streamLobbyItemButtonIcon {
  width: 20px;
  height: 20px;
  display: block;
}

.join-button-queue {
  color: #262626;
  margin: 0 8px 0 10px;
  font-family: ABC Social Bold, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.join-button-price {
  color: #262626;
  font-family: ABC Social Bold, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 110%;
  display: block;
}

.autoChargeText {
  text-align: center;
  color: #b2b2b2;
  margin-top: 8px;
  font-family: ABC Social, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.swiper-button-next-slots, .swiper-button-prev-slots {
  min-width: 48px;
  min-height: 48px;
  background: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 15px 40px #00000026;
  z-index: 2 !important;
  top: initial !important;
  color: #000 !important;
  margin: 0 !important;
  position: absolute !important;
}

.swiper-button-next-slots {
  right: 0 !important;
}

.swiper-button-prev-slots {
  left: 0 !important;
}

@media (max-width: 850px) {
  .customSliderNextBtn, .customSliderPrevBtn {
    display: none;
  }

  .premade-parties-list {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 0 16px;
    display: flex;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    align-self: flex-start;
    display: block;
  }

  .streamLobbyItem {
    height: 618px;
    margin-bottom: 16px;
    padding: 0;
    display: block;
  }

  .streamLobbyItemVideo {
    width: 100%;
    height: 200px;
  }

  .streamLobbyItemInfoWrapper {
    max-width: 100%;
    padding: 8px;
  }

  .streamLobbyItemInfoFirstRow {
    margin-bottom: 12px;
  }

  .serverAndGameFormat {
    color: #b2b2b2;
    font-family: Inter, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }

  .streamLobbyItemDescription {
    white-space: initial;
    height: 45px;
    margin-bottom: 8px;
    padding-left: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 110%;
    overflow: hidden;
  }

  .streamLobbyItemSlotsWrapper {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px 0 16px;
  }

  .streamLobbyItemQueueButtonAdd {
    width: 100%;
    align-items: center;
    margin: 0;
    padding: 14px 21px;
    display: flex;
  }

  .join-button-wrapper {
    align-items: center;
    display: flex;
  }

  .autoChargeText {
    text-align: center;
    margin-top: 4px;
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}

/*# sourceMappingURL=index.b391126a.css.map */
